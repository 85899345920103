import { LegalDoc } from './legaldoc';

import React from 'react';
import './App.css';
import privacysite from './legalfiles/privacysite.json';
import privacy from './legalfiles/privacy.json';
import contact from './legalfiles/contact.json';
import termsofuse from './legalfiles/termsofuse.json';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


export default function App() {
  return (
    <Quiary></Quiary>
  );
}

class Quiary extends React.Component {
  constructor(props) {
    super(props);
    console.log(navigator.language);
    console.log(navigator.userLanguage);
    let userLang = "en";
    if ((/^de\b/.test(navigator.language)) || (/^de\b/.test(navigator.userLanguage))) {
      userLang = "de";
    }
    this.state = { userLang: userLang };
    this.switchLanguage = () => {
      this.setState((state) => {
        // Important: read `state` instead of `this.state` when updating.
        return { userLang: state.userLang === 'de' ? 'en' : 'de' }
      });
    }
  }
  render() {
    return <Router>
      <Switch>
        <Route path="/">
          <Link to="/"><img src="diary.png" width="256" height="256" alt="Quiary logo" class="logo" /></Link>
        </Route>
      </Switch>

      <Switch>
        <Route path="/privacypolicy">
          <LegalDoc content={privacysite.content[this.state.userLang]} title={privacysite.title[this.state.userLang]} />
          <Footer switchLanguage={this.switchLanguage} userLang={this.state.userLang} />
        </Route>
        <Route path="/privacypolicyapp">
          <LegalDoc content={privacy.content[this.state.userLang]} title={privacy.title[this.state.userLang]} />
          <Footer switchLanguage={this.switchLanguage} userLang={this.state.userLang} />
        </Route>
        <Route path="/termsofuse">
          <LegalDoc content={termsofuse.content[this.state.userLang]} title={termsofuse.title[this.state.userLang]} />
          <Footer switchLanguage={this.switchLanguage} userLang={this.state.userLang} />
        </Route>
        <Route path="/contact">
          <LegalDoc content={contact.content[this.state.userLang]} title={contact.title[this.state.userLang]} />
          <Footer switchLanguage={this.switchLanguage} userLang={this.state.userLang} />
        </Route>
        <Route path="/imprint">
          <LegalDoc content={contact.content[this.state.userLang]} title={contact.title[this.state.userLang]} />
          <Footer switchLanguage={this.switchLanguage} userLang={this.state.userLang} />
        </Route>
        <Route path="/">
          <Home userLang={this.state.userLang} />
          <Footer switchLanguage={this.switchLanguage} userLang={this.state.userLang} />
        </Route>
      </Switch>
    </Router >;
  }
}

class Home extends React.Component {

  constructor(props) {
    super(props);
    this.homeTitle = {
      'en': 'Quiary - Your free diary :-)',
      'de': 'Quiary - Dein Tagebuch :-)'
    }
    this.homeText = {
      'en': '<a href="https://apps.apple.com/us/app/id1511158061"><img src="app-store-en.svg" class="badge"/></a><a href="https://play.google.com/store/apps/details?id=app.quiary.app"><img src="play-store-en.png" class="badge"/></a><br /><br />Quiary is your digital diary. <br />Sadly our memory is not perfect and with every day past they tend to fade away or we alter them without even recognising. Quiary can help you to not lose your precious memories. Create one entry a day, set your mood and describe the events and your thought. By this way you can conserve your past and use it for the future.<br /><br />!Your data in your hands!<br />All data is only saved within the app on your device. This means nobody can get access your data without accessing your device. You decide when you create a backup and where you save it.',
      'de': '<a href="https://apps.apple.com/us/app/id1511158061"><img src="app-store-de.svg" class="badge"/></a><a href="https://play.google.com/store/apps/details?id=app.quiary.app"><img src="play-store-de.png" class="badge"/></a><br /><br />Quiary ist Dein digitales Tagebuch. <br />Unser Gedächtnis ist leider sehr flüchtig, mit jedem Tag der vergeht, werden unsere Erinnerungen schwächer und werden verfälscht. Doch jeder Moment ist wertvoll. Deshalb erstelle jeden Tag einen Eintrag mit Deiner Stimmung und beschreibe die erlebten Ereignisse und Gedanken. <br />Lass Quiary Dir helfen, die Vergangenheit zu konservieren und für deine Zukunft zu nutzen.<br /><br />!Deine Daten in deiner Hand!<br />Alle Daten werden ausschließlich auf deinem Gerät gespeichert. D.h. keiner kann diese Daten lesen ohne Zugriff auf dein Gerät. Du bestimmst selber wann du ein Backup erstellt und wo dieses gespeichert wird.'
    }
  }
  render() {
    return <main>
      <div class="content">
        <h1>{this.homeTitle[this.props.userLang]}</h1>
        <div dangerouslySetInnerHTML={{ __html: this.homeText[this.props.userLang] }} />
      </div></main>;
  }
}

class Footer extends React.Component {
  render() {
    return <main id="footer" class="center">
      <Link to="/contact">{contact.title[this.props.userLang]}</Link>&nbsp; | &nbsp;<Link to="/privacypolicy">{privacysite.title[this.props.userLang]}</Link>&nbsp; | &nbsp; <Link to="/privacypolicyapp">{privacy.title[this.props.userLang]}</Link>&nbsp; | &nbsp;<Link to="/termsofuse">{termsofuse.title[this.props.userLang]}</Link>&nbsp; | &nbsp; {"->"} <Link onClick={this.props.switchLanguage}>{this.props.userLang === 'de' ? 'en' : 'de'}</Link>
    </main>;
  }
}